import React, { useEffect, useState } from "react";
import ContactForm from "../components/Contact/ContactForm";
import Map from "../components/Contact/Map";
import Layout from "../components/Layout";
import Hero from "../components/Mobileapp/hero";
import Seo from "../components/Seo";
import allApiData from "../api";
import { contact } from "../api";
import SeoheadData from "../siteScriptData";
import { Container, NavLink, Row, Col, Button } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdLocationPin } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../assets/form_loader.gif";

function Contact() {
  const [footerAddress, setFooterAddress] = useState({});
  const [loading, setLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    pre_mobile: "",
    description: "",
  });

  // const [fullNumber, setFullNumber] = useState("")
  const [errors, setErrors] = useState({});

  const onchange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const onFocus = (e) => {
    const fieldName = e.target.name;
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const onBlur = (e) => {
    const fieldName = e.target.name;
    if (e.target.value.trim() === "") {
      console.log("errors");
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `Enter a value for this field.`,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    }
  };

  useEffect(() => {
    allApiData.getHomeApi().then((response) => {
      console.log("response", response);
      setFooterAddress(response.footer_settings);
    });
  }, []);
  useEffect(() => {
    allApiData.aosinit();
  }, []);

  // useEffect(() => {
  // try {
  //   var f = document.createElement("iframe");
  //   f.src = 'https://forms.zohopublic.in/vasundharainfotech/form/CuntactUSFinal/formperma/PlyShBzMA9Wl_4txQWNf6_kafjIzPKBilf2IxdmuSmo?zf_rszfm=1';
  //   f.style.border = "none";
  //   f.style.height = "100%";
  //   f.style.width = "100%";
  //   f.style.transition = "all 0.5s ease";
  //   f.style.overflow = "hidden";
  //   var d = document.getElementById("zf_div_PlyShBzMA9Wl_4txQWNf6_kafjIzPKBilf2IxdmuSmo");
  //   d.appendChild(f);
  //   window.addEventListener('message', function (event) {
  //     var evntData = event.data;
  //     if (evntData && evntData.constructor == String) {
  //       var zf_ifrm_data = evntData.split("|");
  //       if (zf_ifrm_data.length == 2) {
  //         var zf_perma = zf_ifrm_data[0];
  //         var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
  //         var iframe = document.getElementById("zf_div_PlyShBzMA9Wl_4txQWNf6_kafjIzPKBilf2IxdmuSmo").getElementsByTagName("iframe")[0];
  //         if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
  //           var prevIframeHeight = iframe.style.height;
  //           if (prevIframeHeight != zf_ifrm_ht_nw) {
  //             iframe.style.height = '535px';
  //             // iframe.style.height = zf_ifrm_ht_nw;
  //           }
  //         }
  //       }
  //     }
  //   }, false);
  // } catch (e) { }
  // }, [])

  const notify = (error, msg) => {
    if (error) {
      toast.error(msg);
      return false;
    }
    toast.success(msg);
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.first_name?.length > 191) {
      errors.first_name = "Firstname cannot exceed 191 characters.";
    } else if (inputValues.first_name?.trim() === "") {
      errors.first_name = "Enter a value for this field.";
    }

    if (inputValues.last_name?.length > 191) {
      errors.last_name = "Lastname cannot exceed 191 characters.";
    } else if (inputValues.last_name?.trim() === "") {
      errors.last_name = "Enter a value for this field.";
    }

    if (inputValues.mobile?.trim() === "") {
      errors.mobile = "Enter a number for this field.";
    }

    if (inputValues.description?.length > 500) {
      errors.description = "Description cannot exceed 500 characters.";
    }

    if (!inputValues.email) {
      errors.email = "Enter a value for this field.";
    }
    if (inputValues.email) {
      const email_format = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!email_format.test(inputValues.email)) {
        errors.email = "Enter a valid email address. (eg: yourname@domain.com)";
      }
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const errors = validateValues(inputValues);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        console.log(inputValues, "inputValues11111");
        const res = await contact(inputValues);
        console.log(res, "res111111");
        setInputValues({
          first_name: "",
          last_name: "",
          email: "",
          pre_mobile: "",
          description: "",
        }); // Reset input values
        if (res.success === true) {
          toast.success(res.message);
          console.log(res.success, "ressssssssssssssssss");
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhoneNumberChange = (phoneNumber, country) => {
    const countryCode = country.dialCode;

    let number = countryCode?.length;
    setInputValues({
      ...inputValues,
      ["mobile"]: phoneNumber.slice(number),
      ["pre_mobile"]: "+" + countryCode,
    });
    const updatedErrors = validateValues({
      ...inputValues,
      mobile: phoneNumber.slice(number),
    });
    setErrors(updatedErrors);
  };

  return (
    <Layout>
      <Seo SeoData={SeoheadData.contactusPageSeoData}></Seo>
      <ToastContainer />
      <div className="new-contact-hero">
        <div className="contact-bg">
          <Container>
            <div className="hero-content">
              <div>
                <h1>Contact Us</h1>
                <p>
                  We are here to help you with any queries and questions you may
                  have. Get familiar with us and kick-start with our services.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <div className="contact_form pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={4} lg={5} className="order-2 order-lg-1">
                <div className="form-padding">
                  <div className="contact-space">
                    <div
                      className="left-heading mb-5 d-block d-lg-none"
                      data-aos="zoom-in-down"
                    >
                      <h2>Get in touch</h2>
                    </div>
                    <div
                      className="adress-box gap-lg-3 gap-2"
                      data-aos="zoom-in-left"
                    >
                      <div className="logo-icon ">
                        <FaPhoneAlt />
                      </div>
                      <div className="contact-title flex-wrap">
                        <div className="d-flex">
                          {" "}
                          <h6>Phone </h6> <h6 className="px-2 fw-bold">:</h6>{" "}
                        </div>
                        <div className="information">
                          <NavLink>
                            {footerAddress.mobile1 != "null"
                              ? footerAddress.mobile1
                              : ""}
                          </NavLink>
                          <NavLink>
                            {footerAddress.mobile2 != "null"
                              ? footerAddress.mobile2
                              : ""}
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div
                      className="adress-box gap-lg-3 gap-2"
                      data-aos="zoom-in-right"
                    >
                      <div className="logo-icon  ">
                        <MdEmail />
                      </div>
                      <div className="contact-title flex-nowrap">
                        <div className="d-flex">
                          {" "}
                          <h6>E-mail </h6> <h6 className="px-2 fw-bold">:</h6>{" "}
                        </div>
                        <div className="information">
                          <NavLink>
                            {footerAddress.email1 != "null"
                              ? footerAddress.email1
                              : ""}
                          </NavLink>
                          <NavLink>
                            {footerAddress.email2 != "null"
                              ? footerAddress.email2
                              : ""}
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div
                      className="adress-box adress-box2 gap-lg-3 gap-2"
                      data-aos="zoom-in-left"
                    >
                      <div className="logo-icon ">
                        <MdLocationPin />
                      </div>
                      <div className="contact-title flex-nowrap">
                        <div className="d-flex">
                          {" "}
                          <h6>Address </h6> <h6 className="px-2 fw-bold">:</h6>{" "}
                        </div>
                        <div className="information">
                          {footerAddress.addresses &&
                            footerAddress.addresses.map((_ele, i) => {
                              return (
                                <NavLink key={i} className="mb-2">
                                  {_ele && _ele !== "null" ? _ele : ""}
                                </NavLink>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={8} lg={7} className="order-1 order-lg-2">
                <div className="form-width">
                  {/* <div id="zf_div_PlyShBzMA9Wl_4txQWNf6_kafjIzPKBilf2IxdmuSmo"></div> */}
                  <div className="contact-form">
                    <h3>Share your details to help us serve you better</h3>
                    <div className="quote-form bg-white p-0 mt-4 position-relative">
                      {loading && (
                        <div className="loader_form d-flex align-items-center justify-content-center">
                          <img src={Loader} />
                        </div>
                      )}
                          <div className="d-flex align-items-center justify-content-between quote-input-main ">
                            <label>
                              Name <span>*</span>
                            </label>
                            <div className="input-main d-flex align-items-center gap-3">
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="w-100"
                                  placeholder="First Name"
                                  name="first_name"
                                  onChange={onchange}
                                  value={inputValues.first_name}
                                  onBlur={onBlur}
                                  onFocus={onFocus}
                                />
                                {errors.first_name && (
                                  <p className="error">{errors.first_name}</p>
                                )}
                              </div>
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="w-100"
                                  placeholder="Last Name"
                                  name="last_name"
                                  onChange={onchange}
                                  value={inputValues.last_name}
                                  onBlur={onBlur}
                                  onFocus={onFocus}
                                />
                                {errors.last_name && (
                                  <p className="error">{errors.last_name}</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                            <label>
                              Email <span>*</span>
                            </label>
                            <div className="input-main ">
                              <input
                                type="text"
                                className="w-100"
                                placeholder="Enter Your Valid Email"
                                name="email"
                                onChange={onchange}
                                value={inputValues.email}
                                onBlur={onBlur}
                                onFocus={onFocus}
                              />
                              {errors.email && (
                                <p className="error">{errors.email}</p>
                              )}
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                            <label>
                              Contact Number<span>*</span>
                            </label>
                            <div className="input-main">
                              <div className=" d-flex align-items-center gap-3">
                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  value={inputValues.pre_mobile.concat(
                                    inputValues.mobile
                                  )}
                                  onChange={handlePhoneNumberChange}
                                  onBlur={onBlur}
                                  onFocus={onFocus}
                                />
                              </div>
                              {errors.mobile && (
                                <p className="error">{errors.mobile}</p>
                              )}
                            </div>
                          </div>

                          <div className="d-flex align-items-start justify-content-between quote-input-main mt-4 mt-sm-4">
                            <label>Message</label>
                            <div className="input-main">
                              <textarea
                                className="w-100"
                                placeholder="Brief Project Description"
                                rows={4}
                                name="description"
                                onChange={onchange}
                                value={inputValues.description}
                              />
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-center mt-5">
                            <Button
                              className="submit-form-cos"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                     
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="Cantact" style={{ scrollMarginTop: "90px" }}>
        <div className="pb-lg-5">
          <Map />
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
