import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'

function Map() {
    // const gridIframe = useRef(null);

    // function handleIframe() {
    //     const iframeItem = gridIframe.current;
    //     const divison = typeof window !== "undefined" && iframeItem.contentWindow.getElementsByTagName("div");
    //     console.log(divison);
    // }
    return (
        <div className='map pb-100' >
            <Container>
                {/* <iframe style={{ border: '1px solid #000' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.1994944056473!2d72.82540701533422!3d21.22393638646156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ec681096ef9%3A0x5108669c9dcd7533!2sVasundhara%20Infotech%20LLP!5e0!3m2!1sen!2sin!4v1646116773052!5m2!1sen!2sin"
                    allowfullscreen=""
                    loading="lazy" data-aos="zoom-in" data-aos-duration="1000">
                </iframe> */}
                <iframe style={{ border: '1px solid #000' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.20100694452!2d72.81932311534324!3d21.223876386463616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f5ae4a1578b%3A0x4376fa1e538d8aa9!2sVasundhara%20Infotech!5e0!3m2!1sen!2sin!4v1665989746192!5m2!1sen!2sin"
                    allowfullscreen=""
                    loading="lazy" data-aos="zoom-in" data-aos-duration="1000">
                </iframe>
            </Container>
        </div>
    )
}

export default Map